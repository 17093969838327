<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/perguntas">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/perguntas"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="perguntas-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="name">Descrição</label>
                                <InputText
                                    v-model.trim="form.descricao"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Informe a descrição"
                                    :class="{ 'p-invalid': submitted && !form.descricao }"
                                />
                                <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="ordem">Ordem</label>
                                <InputNumber id="ordem" v-model="form.ordem" autofocus autocomplete="off" placeholder="Informe a ordem" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="tipo">Tipo</label>
                                <Dropdown
                                    id="tipo"
                                    class="selects"
                                    v-model="form.tipo"
                                    :options="tipos"
                                    @change="onChangeTipos"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipo }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="opcoes">Opções</label>
                                <Chips
                                    id="opcoes"
                                    class="chips"
                                    v-model="form.opcoes"
                                    :disabled="form.tipo !== 'ME' && form.tipo !== 'CB'"
                                    placeholder="Informe as opções"
                                    :class="{ 'p-invalid': submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB') }"
                                />
                                <small class="p-error" v-if="submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB')"
                                    >Opções é obrigatório.</small
                                >
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            tipos: [
                {
                    value: 'ME',
                    label: 'Múltipla escolha'
                },
                {
                    value: 'CB',
                    label: 'Combo'
                },
                {
                    value: 'DI',
                    label: 'Dissertativa'
                },
                {
                    value: 'IN',
                    label: 'Inteiro'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/pergunta');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar pergunta' : 'Adicionar pergunta';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        hasError() {
            if (!this.form.descricao) return true;
            if ((this.form.tipo === 'ME' || this.form.tipo === 'CB') && !this.form.opcoes) return true;
            if (!this.form.tipo) return true;
        },
        onChangeTipos(e) {
            if (e.value !== 'ME' && e.value !== 'CB') {
                this.form.opcoes = null;
            }
        }
    },
    components: { AppInfoManual }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}
</style>
